.texto {
    font-family: "Montserrat", sans-serif;
    color: black !important;
    font-size: 12px !important;
    text-align: justify !important;
    text-transform: capitalize !important;
}
.titulo{
    color: #9F2241 !important;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px !important;

}