.footer {
  font-family: "Inter", sans-serif;
  background-color: #262629;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  position: absolute;
  width: 100%;
  color: white;
  margin-top: -0.3%;
}

.link {
  color: #c09b57;
}

.container,
h5 {
  color: white;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
}

.p_foooter {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

a {
  color: white;
  font-weight: 500;
  line-height: normal;
}
a:hover {
  color: #c09b57;
  text-decoration: none;
}
