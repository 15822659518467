.Section {
  padding: 0.5rem 1rem;
  font-family: "Montserrat", sans-serif;
}

.Nav_h2 {
  color: #225b4d;
  font-weight: bold;
}

.subtitle {
  color: black !important;
  font-size: 16px;
  font-weight: lighter;
}
