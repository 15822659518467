.filterRow {
  background-color: #fff;
  padding: 10px;
}

.filterLabel {
  font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  color: #225b4d;
}

.filtros{
  text-transform: capitalize !important;
}